import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getResourceList } from '../../models/BaseModel'
import { ImageReplacementAnchor, FooterNavigation, SocialLinks, BlankErrorBoundary } from '@scrippsproduct/scripps-external-component-library'

import './../../scss/Footer.scss'

export default function Footer() {
    const [navSections, setNavSections] = useState([])
    const [socialLinks, setSocialLinks] = useState([])
    const {isSuccess, data} = useQuery(['footer', {resourceName: 'footer'}], getResourceList)

    useEffect(() => {
        if (isSuccess) {
            setNavSections(data.get('navSections'))
            setSocialLinks(data.get('socialLinks'))
        }
    }, [isSuccess, data])

    return (
        <footer className="main-footer">
            <content-constrainer>
                <div className="footer-logo">
                    <ImageReplacementAnchor href={`/`} 
                        image={{
                            url: 'https://ewscripps.brightspotcdn.com/dims4/default/1a39943/2147483647/strip/true/quality/90/?url=http%3A%2F%2Fewscripps-brightspot.s3.amazonaws.com%2F0d%2F5b%2F05a3f84143998bf407ff67f2fe4d%2Flaff-logo-motto11.webp', 
                            width: 380, 
                            height: 250,
                        }} 
                        height={3.6}
                        baselineAdjustment={0}
                    />
                </div>
                <div className="footer-nav-wrapper">
                    { (isSuccess && navSections.length > 0) && 
                        <BlankErrorBoundary>
                            <FooterNavigation sections={navSections} />
                        </BlankErrorBoundary> 
                    }
                    { (isSuccess && socialLinks.length > 0) && 
                        <BlankErrorBoundary>
                            <SocialLinks size={1.5} showHeader={true} platforms={socialLinks}/>
                        </BlankErrorBoundary> 
                    }
                </div>
                <div className="copyright-notice">
                    {`© ${new Date().getFullYear()} The E.W. Scripps Company, all rights reserved. All third-party trademarks, including logos, are the property of their respective owners.`}
                </div>
            </content-constrainer>
        </footer>
    )
}
