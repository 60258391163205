import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import { getResourceList } from '../../models/BaseModel'
import { ImageReplacementAnchor, InlineNavigation, Loading, LogoNavigation, DrawerNavigation, BlankErrorBoundary } from '@scrippsproduct/scripps-external-component-library'

import './../../scss/Header.scss'

export default function Header() {
    const [menuLinks, setMenuLinks] = useState([])
    const [logoItems, setLogoItems] = useState([])
    const {isLoading, isSuccess, isError, data} = useQuery(['header', {resourceName: 'header'}], getResourceList)
    const mobileNavRef = useRef()

    useEffect(() => {
        if (isSuccess) {
            setMenuLinks(data.get('menuLinks'))
            setLogoItems(data.get('logoItems'))
        }
    }, [isError, isSuccess, data])

    const handleScroll = useDebouncedCallback(() => {
        if (window.scrollY > 10) {
            mobileNavRef.current.classList.add('mobile-nav--shadow');
        } else {
            mobileNavRef.current.classList.remove('mobile-nav--shadow');
        }
        }, 300) 

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);
    
    return (
        <header>
            <content-constrainer class="desktop-nav">
                <ImageReplacementAnchor href={`/`} 
                    image={{
                        url: 'https://ewscripps.brightspotcdn.com/dims4/default/1a39943/2147483647/strip/true/quality/90/?url=http%3A%2F%2Fewscripps-brightspot.s3.amazonaws.com%2F0d%2F5b%2F05a3f84143998bf407ff67f2fe4d%2Flaff-logo-motto11.webp', 
                        width: 380, 
                        height: 250,
                    }} 
                    height={4.5}
                    baselineAdjustment={0}
                />
                { (isLoading) && <Loading />}
                <header-menu-wrapper>
                    { (isSuccess && menuLinks.length > 0) && 
                        <BlankErrorBoundary>
                            <InlineNavigation 
                                links={menuLinks} 
                                classname="main-navigation" 
                                position='right'
                            />
                        </BlankErrorBoundary>
                    } 
                    { (isSuccess && logoItems?.length > 0) && 
                        <LogoNavigation 
                            items={logoItems}
                        />
                    }
                </header-menu-wrapper>
            </content-constrainer>
            <nav className="mobile-nav" ref={mobileNavRef}>
                <content-constrainer class="content-constrainer--no-border">
                    <BlankErrorBoundary>
                        { (isSuccess && menuLinks.length > 0 && logoItems?.length > 0) && 
                            <DrawerNavigation
                                links={[
                                    {
                                        type: 'internal-link',
                                        href: '/',
                                        label: 'Home',
                                    },
                                    ...menuLinks]} 
                                items={logoItems}
                                logo={{
                                    image: {
                                        url: 'https://ewscripps.brightspotcdn.com/dims4/default/1a39943/2147483647/strip/true/quality/90/?url=http%3A%2F%2Fewscripps-brightspot.s3.amazonaws.com%2F0d%2F5b%2F05a3f84143998bf407ff67f2fe4d%2Flaff-logo-motto11.webp', 
                                        width: 380, 
                                        height: 250,
                                    },
                                    href: process.env.REACT_APP_BASE_URL,
                                    height: 3.1,
                                    baselineAdjustment: 0.1
                                }}
                                classname="main-navigation"
                                clipPathStart="polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"
                                clipPathEnd="polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
                                overlayAppendEl={document.querySelector('site-wrapper')}
                            /> 
                        }
                    </BlankErrorBoundary>
                </content-constrainer>
            </nav>
        </header>
    )
}
